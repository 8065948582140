.Layout {
    height: 100%;
    display: flex;
    flex-direction: column;
    /* debug color */
    background-color: aqua;
    box-sizing: border-box;
}

.Layout-Row:last-child{
    border-bottom: 1px solid transparent;
}

.Layout-Row {
    background-color: dodgerblue;
    flex: 1;
    display: flex;

    border-bottom: 1px solid white;
}

.Layout-Cell:last-child{
    border-right: 1px solid transparent;
}
.Selection {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    border: 3px solid white;
}
.Content {
    background-color: chartreuse;
    flex: 1;
}

.Layout-Cell {
    position: relative;
    flex: 1;
    /* border: 1px solid white; */
    /* debug color */
    background-color: brown;
    border-right: 1px solid white;
}
.Layout-Grid {
    display: grid;

    background-color: chocolate;

    grid-template-columns: repeat(4, minmax(200px, 1fr));
    grid-template-rows: repeat(4, minmax(250px, 1fr));

}

.Layout-Item {
    border: 1px solid crimson;
}
